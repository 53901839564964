import gql from 'graphql-tag'

export const GET_ORDERS_LIST = gql`
query workshopOrders(
  $first: Int, 
  $after: String, 
  $before:String, 
  $last:Int, 
  $direction: OrderDirection!, 
  $search: String, 
  $isQuoted: Boolean, 
  $workshop: ID,
  $field: WorkshopOrderSortField!, 
  $isQuoteRequired: Boolean, 
  $status: [ServiceOrderStatusEnum], 
  $hasApprovedQuotation: Boolean, 
  $gte: Date, $lte: Date, 
  $gte_quoted: Date, $lte_quoted: Date,
  $isPendingAcceptance: Boolean,
  $isFullyQuoted: Boolean,
  $isAssignedToMe: Boolean
  ){
    workshopOrders(
      first: $first
      after: $after
      before: $before
      last: $last
      filter: { 
        search: $search, 
        status:$status, 
        isQuoteRequired: $isQuoteRequired, 
        scheduledAt: {gte: $gte, lte: $lte},
        hasApprovedQuotation: $hasApprovedQuotation, 
        isQuoted: $isQuoted,  
        workshop: $workshop, 
        workshopQuotedAt:{gte:$gte_quoted, lte:$lte_quoted},
        isPendingAcceptance: $isPendingAcceptance,
        isFullyQuoted: $isFullyQuoted,
        isAssignedToMe: $isAssignedToMe
      }
      sortBy: {direction: $direction,  field: $field}
    ){
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges{
        node{
          number
          status
          workshopQuotedAt
          workshopAssignedAt
          workshopAcceptedBy{
            firstName
            lastName
          }
          car{
            model{
              id
              name
              make{
                id
                name
              }
            }
            licensePlate
          }
          assignedTo{
            firstName
            lastName
            phone
          }
          isQuoteRequired
          approvedQuotation
          scheduledAt
          isFullyQuoted
          lines{
            service{
              id
              type{
                name
                description
              }
            }
            serviceName
          }        
        }
      }
    }
  }
`

export const GET_ORDER_DETAILS = gql`
query workshopOrder($id: ID!){
  workshopOrder(id: $id){
    number
    status
    car{
      model{
        id
        name
        make{
          id
          name
        }
      }
      licensePlate
      year
      vin
    }
    scheduledAt
    workshopQuotedAt
    workshopAssignedAt
    workshopAcceptedBy{
      firstName
      lastName
    }
    workshopExpectedCompletionAt
    labourHours
    labourRatePerHour{
      amount
      currency
    }
    labourPrice{
      amount
      currency
    }
    workshopExpectedCompletionDays
    partsJob{
      id
      type
      status
      assignedTo{
        firstName
        lastName
        phone
      }
      scheduledAt
      notes
      possibleActions
      createdAt
      updatedAt
      isStarted
      isCompleted
    }
    workshopJobs{
      id
      status
      scheduledAt
      scheduledUpto
      notes
      hoursRequired
      hoursTaken
      createdAt
      updatedAt
      isStarted
      isCompleted
      assignedTo{
        firstName
        lastName
        phone
      }
    }
    canSendToTrello
    assignedTo{
      firstName
      lastName
      phone
    }
    isFullyQuoted
    lines{
      service{
        id
        type{
          name
          description
        }
      }
      serviceName
    }
    partQuotations{
      id
      partName
      priceSupplier{
        currency
        amount
      }
      unitPriceSupplier{
        amount
        currency
      }
      quantity
      quality
      availability
      status
      createdAt
      createdBy{
        firstName
        lastName
      }
      partNumber
      brand
    }
    staffNote
    valetNote
    isQuoteRequired
    workshopTrackingPdfUrl
    attachments{
      file
      filename
      extension
      uploadedAt
      uploadedBy{
        firstName
      }
    }
    approvedQuotation
    comments{
      comment
      createdAt
      createdBy{
        firstName
        lastName
      }
    }
  }
}`

export const MY_WORKSHOPS = gql`
query myWorkshops{
  myWorkshops{
    id
    name
    phone
    address
    location {
      lat
      lng
    }
    contactPerson {
      firstName
      lastName
      phone
      email
    }
  }
}`

export const GET_WORKSHOP_CUSTOMERS = gql`
query WorkshopUsers($search: String!, $first: Int, $after: String, $before:String, $last:Int) {
  workshopUsers(first: $first, last: $last, after: $after, before: $before, filter: {search: $search}) {
    totalCount
    edges {
      node {
        id
        firstName
        lastName
        email
        phone
      }
    }
  }
}
`

export const GET_WORKSHOP_CUSTOMER_CAR = gql`
query WorkshopUserCars($userId: ID!) {
  workshopUserCars(userId: $userId) {
      id
      model {
          id
          name
          make {
              name
              id
          }
      }
      year
      licensePlate
  }
}
`

export const GET_CAR_MAKE = gql`
query {
    vehicleMakes {
      id
      isPopular
      logo
      name
    }
  }
`

export const GET_CAR_MODEL = gql`
query getModels($makeId: ID!){
    vehicleModels(makeId: $makeId) {
      id
      name
      years
      image
      classification
    }
  }
`

export const WORKSHOP_CHECK_SERVICES = gql`
query WorkshopCheckServices($workshopId: ID!, $carModelId: ID) {
  workshopCheckServices(workshopId: $workshopId, carModelId: $carModelId) {
      paymentMethods {
          id
          currency
          rank
          name
          type
      }
      currency
      taxRate
      services {
          id
          price {
              currency
              amount
          }
          type {
              name
              rank
              description
          }
      }
  }
}`


export const MY_JOBS = gql`
query myJobs(
  $first: Int, 
  $after: String, 
  $before:String, 
  $last:Int, 
  $search: String, 
  $status: JobStatusFilterEnum,
  $type: [JobTypeEnum!],
  $isActive: Boolean,
  $isCompleted: Boolean
){
  myJobs(
    first: $first
    after: $after
    before: $before
    last: $last
    filter: { 
      search: $search, 
      status:$status,
      type: $type,
      isActive: $isActive,
      isCompleted: $isCompleted
    }
  ){
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges{
      node{
        type
        status
        scheduledAt
        updatedAt
        order{
          number
          status
          car{
            model{
              name
              make{
                name
              }
            }
            licensePlate
          }
        }
      }
    }
  }
}`

export const GET_WORKSHOP_JOBS = gql`
query workshopJobs(
  $first: Int,
  $after: String,
  $before: String,
  $last: Int,
  $search: String,
  $status: [JobStatusEnum!],
  $type: JobTypeEnum,
  $scheduledAt: DateRangeInput,
  $scheduledUpto: DateRangeInput,
  $workshop: ID
){
  workshopJobs(
    first: $first
    after: $after
    before: $before
    last: $last
    filter: {
      type: $type,
      status: $status,
      scheduledAt: $scheduledAt,
      scheduledUpto: $scheduledUpto,
      workshop: $workshop,
      search: $search,
    }
  ){
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges{
      node{
        id
        type
        status
        scheduledAt
        scheduledUpto
        assignedTo{
          firstName
          lastName
          phone
        }
        isStarted
        isCompleted
        updatedAt
        order{
          number
          status
          car{
            model{
              name
              make{
                name
              }
            }
            licensePlate
          }
        }
      }
    }
  }
}`

export const GET_JOB_USERS = gql`
query jobUsers($jobID: ID!, $first: Int, $search: String) {
  jobUsers(jobId: $jobID, first: $first,  filter: {search: $search}) {
    totalCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        firstName
        lastName
        jobProfile {
          type
        }
      }
    }
  }
}`
